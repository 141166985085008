import React, { useEffect, useState, useRef } from 'react';
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css"; // Import the image gallery styles
import './Service.css';

const Service = () => {
  const Apps = [
    {
      id: 1,
      name: "Acinuts",
      image: require("../assets/Acilogo.png"),
      playstorelink:
        "https://play.google.com/store/apps/details?id=com.acinuts&hl=en-IN",
      description:
        "Online store for the finest selection of nuts! Explore our wide variety of fresh, high-quality nuts sourced from the best farms around the world. Enjoy fast, reliable delivery straight to your doorstep!",
    },
    {
      id: 2,
      name: "Acinuts Order Management",
      image: require("../assets/Aciordermgmt.png"),
      description: 
      "ACINUTS Order Management is an app designed for managing both e-commerce and distributor orders. It offers a wide range of options to help streamline and manage your business efficiently.",
      playstorelink: "",
    },
    {
      id: 3,
      name: "ByteBiller",
      image: require("../assets/byetebiller.webp"),
      description:
        "Streamline mobile billing with a user-friendly interface, professional invoicing, and real-time calculations. Effortlessly manage transactions on the go for a simplified billing experience.",
      playstorelink: "",
    },
  ];

  // Array of images for the ImageGallery
  const images = [
    {
      original: "https://picsum.photos/id/1018/1000/600/",
      thumbnail: "https://picsum.photos/id/1018/250/150/",
    },
    {
      original: "https://picsum.photos/id/1015/1000/600/",
      thumbnail: "https://picsum.photos/id/1015/250/150/",
    },
    {
      original: "https://picsum.photos/id/1019/1000/600/",
      thumbnail: "https://picsum.photos/id/1019/250/150/",
    },
    {
      original: "https://picsum.photos/id/1020/1000/600/",
      thumbnail: "https://picsum.photos/id/1020/250/150/",
    },
    {
      original: "https://picsum.photos/id/1021/1000/600/",
      thumbnail: "https://picsum.photos/id/1021/250/150/",
    },
  ];

  useEffect(() => {
    const handleScroll = () => {
      const sectionIndex = Math.round(window.scrollX / window.innerWidth);
      //setCurrentSection(sectionIndex);
    };

    window.addEventListener('scroll', handleScroll, { passive: true });
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div className="about-container">
      <main className="main">
        <div className="teamdiv">
          <div className="centertitle">
            <h1 className="aboutslogan">Our Recent Mobile App's</h1>
          </div>
          <div className="developer-container">
            {Apps.map((app) => (
              <div className="developer-box" key={app.id}>
                <div className="image-container">
                  <img
                    src={app.image}
                    alt={app.name}
                    className="rounded-image"
                  />
                </div>
                <div className="details">
                  <h3 className="devname">{app.name}</h3>
                  <p className="devdesignation">{app.description}</p>
                </div>
              </div>
            ))}
          </div>

          {/* Image Gallery 
          <div className="gallery-container">
            <ImageGallery
              items={images}
              showThumbnails={true}
              showPlayButton={false}
              showFullscreenButton={false}
              showBullets={true} // Enables pagination dots
              autoPlay={true}
              slideInterval={3000}
            />
          </div>
          */}
        </div>
      </main>
    </div>
  );
};

export default Service;
