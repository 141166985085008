import React, { useState } from 'react';
import './Contact.css';

const Contact = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [interest, setInterest] = useState('');
  const [message, setMessage] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle form submission here
    console.log('Form submitted:', { name, email, phone, interest, message });
  };

  return (
    <div className="contact-component">
      <div className="contact-details">
        {/*
        <div className="online-inquiry">
          <h4 className='subtitlecontact'>ONLINE INQUIRY</h4>
          <form onSubmit={handleSubmit}>
  */}
            {/*
            <input
              type="text"
              placeholder="Name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
              className="input"
            />
            <input
              type="email"
              placeholder="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
              className="input"
            />
            <input
              type="tel"
              placeholder="Phone"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
              required
              className="input"
            />
  */}
            {/*
            <select 
              value={interest} 
              onChange={(e) => setInterest(e.target.value)}
            >
            </select>
              <option value="">Select An Interest</option>
              {/* Add more interest options here */}
{/*
            <textarea
              placeholder="Message"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              required
              className="input"
            />
            <button type="submit" className="input">
              Submit
            </button>
          </form>
        </div>
*/}

        <div className="contact-info">
          <div
            style={{
              alignItems: "center",
              width: "100%",
              justifyContent: "center",
              alignSelf:'center'
            }}
          >
            <h2 className="subtitlecontact">CONTACT US</h2>
          </div>
          <p>Email: guru.nathan17899@gmail.com</p>
          <p>Phone: +91 9751365134, +91 9080344421</p>
          <p>Address: 117, New Street,Keezhamoongiladi</p>
          <p>Chidambaram,Cuddalore,Tamil Nadu- 608 102</p>
          <p></p>
        </div>
      </div>
    </div>
  );
};

export default Contact;
