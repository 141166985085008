import React, { useState, useEffect, useRef } from 'react';
import './Home.css'; // Import your CSS file for styling
import homeimg from '../assets/home.png';
import homeimg1 from '../assets/home1.png';
import About from './About';
import Service from './Service';
import Contact from './Contact';

const Home = () => {
  const [currentSection, setCurrentSection] = useState(0);
  const [showAbout, setShowAbout] = useState(false); // State to control about section visibility
  const [showHome,setShowHome] = useState(false);
  const [showServices,setShowServices] = useState(false)
  const [showContact,setShowcontact] = useState(false)
  const sectionRefs = useRef([]);

  const handleScroll = () => {
    const sectionIndex = Math.round(window.scrollX / window.innerWidth);
    setCurrentSection(sectionIndex);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, { passive: true });
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const scrollToSection = (index) => {
    sectionRefs.current[index].scrollIntoView({ behavior: 'smooth' });
  };

  const handleShowAbout = (e) => {
    e.preventDefault(); // Prevent default link behavior
    setShowAbout(true); // Show the About component
    setShowHome(false)
    setShowcontact(false);
  };

  const handleShowHome = (e) => {
    e.preventDefault(); // Prevent default link behavior
    setShowHome(true); // Show the About component
    setShowAbout(false);
    setShowServices(false)
    setShowcontact(false);
  };


  const handleShowServices = (e) => {
    e.preventDefault(); // Prevent default link behavior
    setShowServices(true);
    setShowHome(false); // Show the About component
    setShowAbout(false);
    setShowcontact(false)
  };

  const handleShowContact = (e) => {
    e.preventDefault(); // Prevent default link behavior
    setShowcontact(true)
    setShowServices(false);
    setShowHome(false); // Show the About component
    setShowAbout(false);
  };

  

  return (
    <div className="home-container">
      <header className="header">
        <div className="logo">
          <h1 className="logo">OMGTechMinds</h1>
        </div>
        <div className="actions">
          <nav className="nav">
            <ul>
              <li>
                <a href="/about" onClick={handleShowHome}>
                  <h2 className="subtitle">Home</h2>
                </a>
              </li>
              <li>
                <a href="/about" onClick={handleShowAbout}>
                  <h2 className="subtitle">About</h2>
                </a>
              </li>
              <li>
                <a href="/services" onClick={handleShowServices}>
                  <h2 className="subtitle">Services</h2>
                </a>
              </li>
              <li>
                <a href="/contact" onClick={handleShowContact}>
                  <h2 className="subtitle">Contact</h2>
                </a>
              </li>
            </ul>
          </nav>
        </div>
      </header>

      <main className="main">
        {showAbout ? (
          <About /> // Conditionally render the About component
        ) : showServices ? (
          <Service />
        ) : showContact?(<Contact/>): (
          <div className="scroll-container">
            <div
              className="section"
              ref={(el) => (sectionRefs.current[0] = el)}
            >
              <div className="hero">
                <div className="hero-content">
                  <h1 className="slogan animate-text">
                    Innovate! Engage! Succeed!
                  </h1>
                </div>
                <div className="hero-image">
                  <img src={homeimg} alt="Home" />
                </div>
              </div>
            </div>

            <div
              className="section"
              ref={(el) => (sectionRefs.current[1] = el)}
            >
              <div className="hero">
                <div className="hero-content">
                  <h1 className="slogan animate-text">
                    Provide a High Quality!!
                  </h1>
                  <h1 className="slogan animate-text">Service and Product</h1>
                </div>
                <div className="hero-image1">
                  <img src={homeimg1} alt="Home" />
                </div>
              </div>
            </div>
          </div>
        )}

        {showHome && (
          <div className="pagination-dots">
            <span
              className={`dot ${currentSection === 0 ? "active" : ""}`}
              onClick={() => scrollToSection(0)}
            ></span>
            <span
              className={`dot ${currentSection === 1 ? "active" : ""}`}
              onClick={() => scrollToSection(1)}
            ></span>
          </div>
        )}
      </main>
    </div>
  );
};

export default Home;