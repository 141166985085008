import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import Home from './Screens/Home';
import About from './Screens/About';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';


function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
      </Routes>
    </Router>
  );
}

export default App;
