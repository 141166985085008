import React, { useState, useEffect, useRef } from 'react';
import './About.css'
import homeimg from '../assets/dev.png';

const About = () => {
  const [currentSection, setCurrentSection] = useState(0);
  const sectionRefs = useRef([]);

  const handleScroll = () => {
    const sectionIndex = Math.round(window.scrollX / window.innerWidth);
    setCurrentSection(sectionIndex);
  };

  const developers = [
    { id: 1, name: "Yuvasundhar", designation: "Full Stack Developer", image: require("../assets/uva.jpeg") },
    { id: 2, name: "Vasanth", designation: "Full Stack Developer", image: require("../assets/Vasanth.jpg") },
    { id: 3, name: "Sathish", designation: "Backend Developer", image: require("../assets/sathish.jpeg") },    
    { id: 4, name: "Vicky Ponting", designation: "Mobile App Developer", image: require("../assets/sathish.jpeg") },    
    
    
  ];


  useEffect(() => {
    window.addEventListener('scroll', handleScroll, { passive: true });
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const scrollToSection = (index) => {
    sectionRefs.current[index].scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <div className="about-container">      

      <main className="main">
        
          <div className="section" ref={(el) => (sectionRefs.current[0] = el)}>
            <div className="hero">
            <div className="hero-image">
                <img src={homeimg} alt="Home" />
              </div>
              <div className="hero-content">
              <div className="hero-item">
                <i className="fas fa-question-circle icon"></i>
                <h1 className="aboutslogan animate-text">Go Digital!!</h1>
              </div>
              <div className="hero-item">
                <i className="fas fa-question-circle icon"></i>
                <h1 className="aboutslogan animate-text">Grow Global!</h1>
              </div>              
              </div>
             
            </div>
          </div>

       <div className='topic'>
        <div className='centertitle'>
        <h1 className='aboutslogan'>Who We Are?</h1>
        </div>
        <p className='normaltext'>
        We are a dedicated team of technology enthusiasts focused on creating custom mobile and web applications that propel your business to new heights. Our solutions are crafted with precision to meet your unique business requirements, providing seamless performance and intuitive user experiences. With a strong emphasis on innovation and quality, we aim to enhance your operational efficiency and drive meaningful growth. Whether you're looking to streamline your processes, expand your digital presence, or improve customer engagement, we’re here to provide solutions that transform your business. We work closely with you to understand your goals and deliver cutting-edge technology that takes you to the next level.
        </p>
       </div>

       <div className='teamdiv'>
        <div className='centertitle'>
          <h1 className='aboutslogan'>Meet Our Team</h1>
        </div>
        <div className='developer-container'>
        {developers.map((developer) => (
        <div className="developer-box" key={developer.id}>
          <div className="image-container">
            <img src={developer.image} alt={developer.name} className="rounded-image" />
          </div>
          <div className="details">
            <h3 className="devname">{developer.name}</h3>
            <p className="devdesignation">{developer.designation}</p>
          </div>
        </div>
      ))}
      </div>
       </div>
  
             
      </main>
    </div>
  );
};

export default About;